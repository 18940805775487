import html2canvas from 'html2canvas'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useQuery } from 'react-apollo'
import api from '../../api/index.js'
import { GET_USER } from '../../gql'
import { db } from '../../lib/db.js'
import { convertDataURIToBinary } from '../../lib/helpers'
import './PrintDocumentStyle.scss'

const PrintDocument = ({
  id,
  lastOrderId,
  products,
  customer,
  address,
  city,
  plate,
  t,
  signature,
  closeDoc,
  forwarder,
  code,
}) => {
  const { data } = useQuery(GET_USER, {
    variables: {
      id: jwtDecode(localStorage.getItem('access_token')).id,
    },
  })

  const printDocument = async () => {
    const accessToken = localStorage.getItem('access_token')
    const input = document.getElementById('divToPrint')
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        let binary
        let blob
        if (navigator.onLine) {
          binary = convertDataURIToBinary(imgData)
          blob = new Blob([binary], { type: 'image/png' })
          try {
            api.uploadFile(id, blob, accessToken, 'signature')
          } catch (e) {
            console.log('error sending delivery')
          }
        }
        db.offlineOrders.update(lastOrderId, {
          signature: imgData,
        })
      })
      .catch((error) => {
        db.offlineOrders.update(lastOrderId, {
          errorSign: error,
        })
      })
  }

  useEffect(() => {
    if (id) {
      printDocument()
    }
  }, [id])

  return (
    <div role="button" className="print" id="divToPrint" onClick={() => closeDoc()}>
      <div className="center">
        <img className="bdn-logo" src="/images/bdn-logo.png" alt="" />
      </div>
      <div className="center print-header">
        <p>{moment().format('DD/MM/YY HH:mm')}</p>
        <p>
          {t('deliveryCode')} {code}
        </p>
      </div>
      <div className="separator" />
      <p>{t('recipient')}</p>
      <p>{customer}</p>
      <p>{address}</p>
      <p>{city}</p>
      <p>{plate}</p>
      <div className="separator" />
      {forwarder && (
        <p>
          {t('forwarder')} {forwarder}
        </p>
      )}
      <p>{t('sender')}</p>
      {data?.user && (
        <div>
          <p>
            {t('truckDriver')} {data.user.firstname} {data.user.lastname}
          </p>
          <p>{data.user.siteByIdSite.name}</p>
        </div>
      )}
      <div className="separator" />
      <p>{t('instruction')}</p>
      <p>{t('articles')}</p>
      <div>
        <ul>
          {products.map((product) => (
            <li key={product.description}>
              <div className="d-flex">
                <p className="ref">{t('ref')} </p>
                <div>
                  <p>{product.description || product.materialByIdMaterial.description}</p>
                  <p>
                    : {product.delivered ? product.delivered.toFixed(3) : '0'} {t('tons')}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="separator" />
      <p>
        {t('totalWeight')}{' '}
        {products
          ?.map((o) => o.delivered)
          ?.reduce((a, c) => a + c)
          .toFixed(3)}{' '}
        {t('tons')}
      </p>
      <div className="separator" />
      <div className="center">
        <img className="sign" src={signature} alt="" />
      </div>
      <div className="separator" />
      <p className="weight-600">{t('bdn')}</p>
      <p className="mt-2 mb-2">{t('bdnTagline')}</p>
      <p>{t('bdnLegalMentions1')}</p>
      <p>{t('bdnLegalMentions2')}</p>
      <p>{t('bdnLegalMentions3')}</p>
      <p>{t('bdnLegalMentions4')}</p>
      <p>{t('bdnLegalMentions5')}</p>
      <p>{t('bdnLegalMentions6')}</p>
      <p>{t('bdnLegalMentions7')}</p>
      <p>{t('bdnLegalMentions8')}</p>

      <div className="space-blanck" />
    </div>
  )
}

PrintDocument.propTypes = {
  id: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customer: PropTypes.string.isRequired,
  address: PropTypes.string,
  plate: PropTypes.string,
  city: PropTypes.string,
  signature: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  closeDoc: PropTypes.func.isRequired,
  lastOrderId: PropTypes.number,
  forwarder: PropTypes.string,
  code: PropTypes.string,
}

PrintDocument.defaultProps = {
  lastOrderId: null,
  address: '',
  city: '',
  plate: '',
  forwarder: '',
  code: '',
}

export default PrintDocument
